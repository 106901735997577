import React, { useState } from "react";
import CommonDialog from "../CommonDialog";
import { useMutation, useQuery } from "react-query";
import {
  getTransportFeeStructure,
  linkTransportFeeWithRange,
} from "app/services/schoolService/transport.service";
import { selectActiveSession } from "app/modules/schools/selectors";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import CustomLinearProgress from "../../CustomLinearProgress";
import { Button, DialogActions, DialogContent, MenuItem } from "@mui/material";
import TextFieldLabel from "../../TextFieldLabel";
import { CustomSelectBox } from "assets/styles/globalStyledComponent";
import { IndianCurrency } from "app/utils/helper";
import { LoadingButton } from "@mui/lab";

const LinkFeeStructureDialog = ({
  open = false,
  onClose = () => {},
  refetch = () => {},
  selectedFeeStructure = {},
}) => {
  const selectedSession = useSelector(selectActiveSession);
  const [state, setState] = useState({
    transportFeeList: [],
    selectedFeeStructure: selectedFeeStructure?._id || "",
  });

  const {
    isLoading: getTransportFeeStructureLoading,
    isFetching: getTransportFeeStructureFetching,
  } = useQuery({
    queryKey: ["getTransportFeeStructureForLinkFeeStructure"],
    queryFn: () =>
      getTransportFeeStructure({
        session: selectedSession,
      }),
    onSuccess: (res) => {
      setState((prev) => ({
        ...prev,
        transportFeeList: res?.data?.list ?? [],
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const {
    mutate: linkTransportFeeWithRangeMutate,
    isLoading: linkTransportFeeWithRangeLoading,
  } = useMutation(linkTransportFeeWithRange, {
    onSuccess: ({ data }) => {
      toast.success(data?.message);
      refetch();
      setState((prev) => ({
        ...prev,
        selectedFeeStructure: "",
      }));
      onClose();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  return (
    <>
      <CommonDialog
        open={open}
        onClose={onClose}
        title="Link Fee Structure"
        minWidth="600px"
      >
        <DialogContent>
          <CustomLinearProgress
            loading={
              getTransportFeeStructureLoading ||
              getTransportFeeStructureFetching
            }
          />
          <TextFieldLabel title={"Fee Structure"} required />

          {state?.transportFeeList?.length > 0 ? (
            <CustomSelectBox
              native
              displayEmpty
              fullWidth
              size="small"
              onChange={(e) => {
                setState((prev) => ({
                  ...prev,
                  selectedFeeStructure: e.target.value,
                }));
              }}
              value={state.selectedFeeStructure}
            >
              <MenuItem value="" component="option">
                Select
              </MenuItem>
              {state?.transportFeeList?.map((item) => (
                <MenuItem key={item?._id} value={item?._id} component="option">
                  {item?.feeCode} : {`${IndianCurrency(item?.feeAmount)}`}
                </MenuItem>
              ))}
            </CustomSelectBox>
          ) : (
            <TextFieldLabel title="No Fee Structure Available" />
          )}
        </DialogContent>

        <DialogActions>
          <LoadingButton
            loading={linkTransportFeeWithRangeLoading}
            variant="contained"
            color="secondary"
            onClick={() => {
              if (!state?.selectedFeeStructure) {
                toast.error("Please select fee structure");
                return;
              }
              linkTransportFeeWithRangeMutate({
                transportFeeDocId: state?.selectedFeeStructure,
                rangeDocId: selectedFeeStructure?._id,
              });
            }}
          >
            Link Fee Structure
          </LoadingButton>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default LinkFeeStructureDialog;
