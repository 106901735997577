import React from "react";
import { useMutation } from "react-query";
import { DialogContent, DialogActions, Grid, Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import CustomLoader from "../../CustomLoader";
import TextFieldLabel from "../../TextFieldLabel";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import { toast } from "react-toastify";
import * as yup from "yup";
import { queryClient } from "App";
import {
  addTransportRoute,
  updateTransportRoute,
} from "app/services/schoolService/transport.service";
import { useFormik } from "formik";
import CommonDialog from "../CommonDialog";
import { useSelector } from "react-redux";
import { selectActiveSession } from "app/modules/schools/selectors";

const validationSchema = yup.object({
  routeNumber: yup
    .number("Please enter only numbers")
    .required("Route number required"),
  routeName: yup
    .string("Please enter alphabets")
    .required("Route name required"),
  routeCode: yup
    .string("Please enter alphabets")
    .required("Route name required"),
});
const AddRouteDialog = ({
  open = false,
  routeData = {},
  onClose = () => {},
  refetch = () => {},
}) => {
  const selectedSession = useSelector(selectActiveSession);

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues: {
      routeDocId: routeData?._id,
      routeNumber: routeData?.routeNumber,
      routeName: routeData?.routeName,
      routeCode: routeData?.routeCode,
    },
    validationSchema,
    onSubmit: (formValues) => {
      // if docId present meaning edit
      if (routeData?._id) {
        const body = {
          session: selectedSession,
          routeDocId: formValues.routeDocId,
          routeCode: formValues.routeCode,
          routeName: formValues.routeName,
        };
        updateRouteMutate(body);
      } else {
        const body = {
          session: selectedSession,
          routeNumber: formValues.routeNumber,
          routeName: formValues.routeName,
          routeCode: formValues.routeCode,
        };
        addTransportRouteMutate(body);
      }
    },
  });
  // update Route
  const {
    mutate: updateRouteMutate,
    isLoading: updateRouteLoading,
    data: updateRouteData,
  } = useMutation("updateRoute", updateTransportRoute, {
    onSuccess: (tranportdetaildata) => {
      toast.success(updateRouteData?.data?.message);
      onClose();
      queryClient.invalidateQueries(["getAllRouteList_RouteDetail"]);
      queryClient.invalidateQueries(["getAllRoute"]);
      queryClient.invalidateQueries(["getRouteDataByDocId"]);
      refetch();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
    // onSettled: () => {
    //   queryClient.invalidateQueries(["getAllRoute"]);
    //   queryClient.invalidateQueries(["getRouteDataByDocId"]);
    // },
  });
  // add route
  const {
    mutate: addTransportRouteMutate,
    isLoading: addTransportRouteLoading,
  } = useMutation("addTransportRoute", addTransportRoute, {
    onSuccess: ({ data }) => {
      toast.success(data?.message);
      onClose();
      queryClient.invalidateQueries(["getAllRoute"]);
      queryClient.invalidateQueries(["getAllRouteList_RouteDetail"]);
      queryClient.invalidateQueries(["getRouteDataByDocId"]);
      refetch();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || "Something went wrong");
    },
    // onSettled: () => {
    //   queryClient.invalidateQueries(["getAllRoute"]);
    //   queryClient.invalidateQueries(["getRouteDataByDocId"]);
    // },
  });

  return (
    <>
      <CustomLoader show={updateRouteLoading || addTransportRouteLoading} />

      <CommonDialog
        title={`${routeData?._id ? "Edit" : "Add"} Route`}
        open={open}
        onClose={onClose}
        minWidth="600px"
      >
        <DialogContent>
          <form onSubmit={handleSubmit} id="routeDetaiForm">
            <Grid container spacing={2} sx={{ pt: 1 }}>
              <Grid item xs={6}>
                <TextFieldLabel title="Enter Route Number" required />
                <CustomTextField
                  disabled={routeData?._id ? true : false}
                  fullWidth
                  size="small"
                  placeholder=" enter Vehicle Number"
                  name="routeNumber"
                  type="number"
                  onChange={handleChange}
                  value={values.routeNumber}
                  error={touched.routeNumber && Boolean(errors.routeNumber)}
                  helperText={touched.routeNumber && errors.routeNumber}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="Enter Route Code" required />
                <CustomTextField
                  fullWidth
                  size="small"
                  name="routeCode"
                  placeholder="Route Code"
                  onChange={handleChange}
                  value={values.routeCode}
                  error={touched.routeCode && Boolean(errors.routeCode)}
                  helperText={touched.routeCode && errors.routeCode}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ pt: 1 }}>
              <Grid item xs={12}>
                <TextFieldLabel title="Enter Route Name" required />
                <CustomTextField
                  fullWidth
                  size="small"
                  name="routeName"
                  placeholder="route Name"
                  onChange={handleChange}
                  value={values.routeName}
                  error={touched.routeName && Boolean(errors.routeName)}
                  helperText={touched.routeName && errors.routeName}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2 }} />
          </form>
        </DialogContent>
        <DialogActions>
          {routeData?._id ? (
            <LoadingButton
              form="routeDetaiForm"
              type="submit"
              loading={updateRouteLoading}
              variant="contained"
              color="secondary"
              sx={{ fontSize: "14px", textTransform: "capitalize", px: 3 }}
            >
              Update
            </LoadingButton>
          ) : (
            <LoadingButton
              type="submit"
              form="routeDetaiForm"
              loading={addTransportRouteLoading}
              color="secondary"
              variant="contained"
              sx={{ fontSize: "14px", textTransform: "capitalize", px: 3 }}
            >
              Submit
            </LoadingButton>
          )}
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default AddRouteDialog;
