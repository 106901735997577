import API_ENDPOINTS from "app/config/endpoints";
import { schoolTokenAxios, authTokenAxios } from "../axios";

export const getAllRouteList = () => {
  return schoolTokenAxios.get(API_ENDPOINTS.studentManagement.getAllRouteList);
};

export const getAllFeeStructure = (medium, session, classDocId, section) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getAllFeeStructure}`,
    {
      params: {
        session: session,
        medium: medium,
        classDocId: classDocId,
        section: section,
        status: "APPROVED",
      },
    }
  );
};

export const getAllFeeStructureForEdit = (session, classDocId, section) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getAllFeeStructure}`,
    {
      params: {
        session: session,
        classDocId: classDocId,
        section: section,
        status: "APPROVED",
      },
    }
  );
};

export const getTransportFeeByStopName = (stopName, session) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getTransportFeeByStopName}`,
    {
      params: { session: session, stopName: stopName },
    }
  );
};

export const getTransportFeeByStopNameAndSession = (stopName, session) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getTransportFeeByStopName}?stopName=${stopName}&session=${session}`
  );
};
export const confirmMobileNumber = (body) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.studentManagement.confirmMobileNumber}`,
    body
  );
};
export const verifyOtNumberLogic = (body) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.studentManagement.verifyOtpNumber}`,
    body
  );
};

export const getClassDropdown = (selectedSchoolId, medium) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.commonApi.getClassDropdown}/${selectedSchoolId}?medium=${medium}`
  );
};

export const uploadDocumentForStudent = (payload) => {
  return schoolTokenAxios.put(
    API_ENDPOINTS.studentManagement.uploadDocumentForStudent,
    payload
  );
};

export const getAllDocumentByStudentDocId = (studentDocId) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getAllDocumentByStudentDocId}`,
    {
      params: {
        studentDocId: studentDocId,
      },
    }
  );
};

export const getLastAdmissionCount = (payload) => {
  return schoolTokenAxios.get(
    API_ENDPOINTS.studentManagement.getLastAdmissionCount
  );
};

export const registerFreshStudentBySchool = (payload) => {
  return schoolTokenAxios.post(
    API_ENDPOINTS.studentManagement.registerFreshStudentBySchool,
    payload
  );
};

export const getStudentsForGivenList = (body) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.studentManagement.getStudentsForGivenList}`,
    body
  );
};

export const getStudentsByClassDocId = (classDocId, section, session) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getStudents1}`,
    {
      params: {
        session: session,
        classDocId: classDocId,
        section: section,
      },
    }
  );
};
export const getStudentsListLogic = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getStudents1}`,
    {
      params,
    }
  );
};
export const getAppDownloadData = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getAppDownloadData}`,
    {
      params,
    }
  );
};
export const updateStudentProfileLogic = (body) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.studentManagement.updateStudentProfile}`,
    body
  );
};
export const getStudentForPromotionOrMigrationLogic = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getStudentForPromotionOrMigration}`,
    {
      params,
    }
  );
};
export const suspendStudent = (body) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.studentManagement.suspendStudent}`,
    body
  );
};

export const getStudentsByTransport = (vehicleNumber, selectedSession) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getStudents1}`,
    {
      params: {
        vehicleNumber: vehicleNumber,
        session: selectedSession,
      },
    }
  );
};

export const getStudentsByStopName = (routeNumber, to, session) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getStudents1}`,
    {
      params: {
        session: session,
        routeNumber: routeNumber,
        to: to,
      },
    }
  );
};

export const getStudentsByCategory = (
  classDocId,
  section,
  session,
  category
) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getStudents1}`,
    {
      params: {
        session: session,
        classDocId: classDocId,
        section: section,
        category: category,
      },
    }
  );
};

export const getStudentsByCommunity = (
  classDocId,
  section,
  session,
  community
) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getStudents1}`,
    {
      params: {
        session: session,
        classDocId: classDocId,
        section: section,
        community: community,
      },
    }
  );
};

export const getTransport = () => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getTransport}`,
    {
      params: { status: "ACTIVE" },
    }
  );
};

export const getStudentsByMobileNumber = (mobileNumber, session) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getStudents1}`,
    {
      params: {
        mobileNumber: mobileNumber,
        session: session,
      },
    }
  );
};
export const getStudentsByAdmissionNumber = (admissionNumber, session) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getStudents1}`,
    {
      params: {
        admissionNumber: admissionNumber,
        session: session,
      },
    }
  );
};

export const getStudentsByName = (studentName, session) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getStudents1}`,
    {
      params: {
        session: session,
        firstName: studentName,
      },
    }
  );
};
export const checkAadharExist = (aadhar) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.checkAadharExist}/${aadhar}`
  );
};
export const getStudentsByAdmissionDate = (
  fromDate,
  toDate,
  selectedSession
) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getStudents1}`,
    {
      params: {
        admissionFrom: fromDate,
        admissionTo: toDate,
        session: selectedSession,
      },
    }
  );
};

// export const getProfileByStudentDocId = (studentDocId) => {
//   return schoolTokenAxios.get(
//     `${API_ENDPOINTS.studentManagement.getProfileByStudentDocId}/${studentDocId}`
//   );
// };

export const migrateStudent = (body) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.studentManagement.migrateStudent}`,
    body
  );
};
export const updateMigratedStudent = (body) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.studentManagement.updateMigratedStudent}`,
    body
  );
};
export const getStudentDataForMigrate = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getStudentDataforMigrate}`,
    {
      params,
    }
  );
};
export const getRollNumberInRequestedOrder = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getRollNumberInRequestedOrder}`,
    {
      params,
    }
  );
};
export const getTcById = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.studentManagement.getTcById}`, {
    params,
  });
};

export const getTransportForStudent = (studentDocId) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getTransportForStudent}/${studentDocId}`
  );
};

export const getFeeCollectionByStudentSession = (studentDocId, session) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getFeeCollectionByStudentSession}?studentDocId=${studentDocId}&session=${session}`
  );
};
export const getFeeCollectionForStudentProfile = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getFeeCollectionForStudentProfile}`,
    { params }
  );
};
export const collectFee = (body) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.studentManagement.collectFee}`,
    body
  );
};
export const getFeeCollectionForPay = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getFeeCollectionForPay}`,
    { params }
  );
};
export const deleteFee = (body) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.studentManagement.deleteFee}`,
    body
  );
};
export const deleteInvoice = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.deleteInvoice}`,
    {
      params,
    }
  );
};
export const updateAdmissionFee = (body) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.studentManagement.updateAdmissionFee}`,
    body
  );
};
export const updateMonthlyFee = (body) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.studentManagement.updateMonthlyFee}`,
    body
  );
};
export const changeAdharNumber = (body) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.studentManagement.changeAdharNumber}`,
    body
  );
};
export const getAttendanceBySessionForStudent = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getAttendanceBySessionForStudent}`,
    { params }
  );
};

export const updateAdmissionInfo = (payload) => {
  return schoolTokenAxios.put(
    API_ENDPOINTS.studentManagement.updateAdmissionInfo,
    payload
  );
};
export const updateClassInfoLogic = (payload) => {
  return schoolTokenAxios.put(
    API_ENDPOINTS.studentManagement.updateClassInfo,
    payload
  );
};

export const updateTransportInfoInStudentProfile = (payload) => {
  return schoolTokenAxios.put(
    API_ENDPOINTS.studentManagement.updateTransportInfoInStudentProfile,
    payload
  );
};

export const updateHostelInfo = (payload) => {
  return schoolTokenAxios.put(
    API_ENDPOINTS.studentManagement.updateHostelInfo,
    payload
  );
};

export const updatePreSchoolInfo = (payload) => {
  return schoolTokenAxios.put(
    API_ENDPOINTS.studentManagement.updatePreSchoolInfo,
    payload
  );
};

export const updateAddressInfo = (payload) => {
  return schoolTokenAxios.put(
    API_ENDPOINTS.studentManagement.updateAddressInfo,
    payload
  );
};

export const updateStudentPersonalInfoBySchool = (payload) => {
  return schoolTokenAxios.put(
    API_ENDPOINTS.studentManagement.updateStudentPersonalInfoBySchool,
    payload
  );
};

export const updateParentInfo = (payload) => {
  return schoolTokenAxios.put(
    API_ENDPOINTS.studentManagement.updateParentInfo,
    payload
  );
};

export const putPromoteStudent = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.studentManagement.promoteStudent}`,
    payload
  );
};
export const addExistingStudent = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.studentManagement.addExistingStudent}`,
    payload
  );
};
export const admissionApproveStudent = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.studentManagement.admissionApproveStudent}/${payload?.requestDocId}`,
    payload.body
  );
};

export const getAdmissionRequests = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.studentManagement.getAdmissionRequests}`,
    payload
  );
};

export const getStudentProfileByRequestDocId = (requestDocId) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getStudentProfileByRequestDocId}?admissionRequestDocId=${requestDocId}`
  );
};

export const rejectAdmissionRequest = (requestDocId) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.rejectAdmissionRequest}/${requestDocId}`
  );
};
export const approveAdmissionRequest = (requestDocId, payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.studentManagement.approveAdmissionRequest}/${requestDocId}`,
    payload
  );
};

export const getAlumni = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.studentManagement.getAlumni}`, {
    params,
  });
};

export const getInvoiceDetail = (invoiceNumber) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getInvoiceDetail}?invoiceNumber=${invoiceNumber}`
  );
};

export const getFeeReport = (payload) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.fees.getFeeReport}`, {
    params: payload,
  });
};
export const getAggregateFeeCollectionReport = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.fees.aggregateFeeCollectionReport}`,
    {
      params: {
        medium: params.medium,
        month: params.month,
        year: params.year,
      },
    }
  );
};

export const getWholeStudentName = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.studentManagement.getWholeStudentName}`,
    {
      params: {
        ...params,
      },
    }
  );
};
