import React, { useEffect, useState } from "react";
import TextFieldLabel from "../common/TextFieldLabel";
import { CustomSelectBox } from "assets/styles/globalStyledComponent";
import { MenuProps } from "app/utils/helper";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { getTransport } from "app/services/student-management";

const ByVehicleFilter = ({
  prevVehicleList,
  setSelectedVehicles,
  label = false,
}) => {
  const [state, setState] = useState({
    vehicleNumber: [],
    vehicleNumberList: [],
  });

  const { isLoading: getTransportLoading, isFetching: getTransportFetching } =
    useQuery({
      queryKey: ["getTransport"],
      queryFn: () => getTransport({ status: "ACTIVE" }),
      onSuccess: (res) => {
        if (prevVehicleList.length > 0) {
          let updatedVehicleList = res?.data?.list.map((item) => {
            return {
              ...item,
              checked: prevVehicleList.some(
                (prevItem) =>
                  prevItem.vehicleNumber === item.vehicleNumber &&
                  prevItem?.checked === true
              ),
            };
          });
          setState((prev) => ({
            ...prev,
            vehicleNumberList: updatedVehicleList,
          }));
        } else {
          setState((prev) => ({
            ...prev,
            vehicleNumberList: res?.data?.list,
          }));
        }
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
    });

  useEffect(() => {
    if (state.vehicleNumberList.length > 0) {
      setSelectedVehicles(state.vehicleNumberList);
    }
  }, [state.vehicleNumberList]);

  return (
    <>
      {label && <TextFieldLabel title={"Select Vehicle"} />}
      <CustomSelectBox
        MenuProps={MenuProps}
        multiple
        displayEmpty
        size="small"
        fullWidth
        value={state.vehicleNumber}
        renderValue={() => {
          const d = state.vehicleNumberList?.filter((item) => item?.checked);
          if (d.length > 0) {
            return (
              <Typography sx={{ fontWeight: 500, pr: 1 }}>
                {d?.map((item) => item?.vehicleNumber)?.join(", ")}
              </Typography>
            );
          } else {
            return <Typography>Select</Typography>;
          }
        }}
      >
        {state.vehicleNumberList?.map((item, i) => (
          <Box key={i} sx={{ px: 1 }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={item?.checked}
                  onChange={(e) => {
                    item.checked = !item.checked;
                    setState((prev) => ({ ...prev }));
                  }}
                />
              }
              label={item?.vehicleNumber}
              componentsProps={{
                typography: {
                  fontSize: "14px",
                  fontWeight: 600,
                },
              }}
            />
          </Box>
        ))}
      </CustomSelectBox>
    </>
  );
};

export default ByVehicleFilter;
