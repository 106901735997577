import React from "react";
import { useMutation } from "react-query";
import {
  DialogContent,
  DialogActions,
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import CustomLoader from "../../CustomLoader";
import TextFieldLabel from "../../TextFieldLabel";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import { toast } from "react-toastify";
import * as yup from "yup";
import { queryClient } from "App";
import { useFormik } from "formik";
import CommonDialog from "../CommonDialog";
import {
  addTransportStop,
  updateTransportStop,
} from "app/services/schoolService/transport.service";
import { useSelector } from "react-redux";
import { selectActiveSession } from "app/modules/schools/selectors";

const validationSchema = yup.object({
  stopName: yup
    .string("Please enter alphabets")
    .required("Stop number required"),
  distance: yup
    .number("Please enter only numbers")
    .required("Stop name required"),
  priority: yup
    .number("Please enter only numbers")
    .required("Stop name required"),
});
const AddStopName = ({
  open = false,
  stopData = {},
  routeData = {},
  onClose = () => {},
  refetch = () => {},
}) => {
  const isEdit = stopData?._id ? true : false;

  const session = useSelector(selectActiveSession);

  const { values, errors, touched, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        rangeDocId: stopData?.rangeDocId,
        // stopName: stopData?.stopName,
        distance: stopData?.distance,
        // priority: stopData?.priority,
        duration: stopData?.duration,
      },
      validationSchema,
      onSubmit: (formValues) => {
        // if docId present meaning edit
        if (isEdit) {
          const body = {
            session: session,
            stopDocId: stopData?._id,
            rangeDocId: formValues?.rangeDocId,
            stopName: formValues?.stopName,
            distance: formValues?.distance,
            priority: formValues?.priority,
            // duration: formValues?.duration,
          };
          updateStopMutate(body);
        } else {
          const body = {
            session: session,
            rangeDocId: formValues?.rangeDocId,
            stopName: formValues?.stopName,
            distance: formValues?.distance,
            priority: formValues?.priority,
            // duration: formValues?.duration,
          };
          addStopMutate(body);
        }
      },
    });
  // update Stop
  const {
    mutate: updateStopMutate,
    isLoading: updateStopLoading,
    data: updateStopData,
  } = useMutation("updateStop", updateTransportStop, {
    onSuccess: (tranportdetaildata) => {
      toast.success(updateStopData?.data?.message);
      onClose();
      refetch();
    },
    onError: (errorResponse) => {
      toast.error(errorResponse?.response.data?.message);
    },
  });

  // add route
  const { mutate: addStopMutate, isLoading: addStopLoading } = useMutation(
    "addStop",
    addTransportStop,
    {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        onClose();
        refetch();
      },
      onError: (errorResponse) => {
        toast.error(errorResponse?.response?.data?.message);
      },
    }
  );

  React.useEffect(() => {
    if (stopData?._id) {
      setFieldValue("stopName", stopData?.stopName);
      setFieldValue("distance", stopData?.distance);
      setFieldValue("priority", stopData?.priority);
    }
  }, [
    stopData?._id,
    stopData?.stopName,
    stopData?.distance,
    stopData?.priority,
    setFieldValue,
  ]);

  return (
    <>
      <CustomLoader show={updateStopLoading || addStopLoading} />

      <CommonDialog
        title={`${stopData?._id ? "Update" : "Add"} Stop`}
        open={open}
        onClose={onClose}
        minWidth="600px"
      >
        <DialogContent>
          {routeData?.routeName && (
            <>
              <Typography sx={{ fontSize: 14 }}>Route Info: </Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                {routeData?.routeNumber} : {routeData?.routeCode} :{" "}
                {routeData?.routeName}
              </Typography>
            </>
          )}
          <form onSubmit={handleSubmit} id="stopDetaiForm">
            <Typography sx={{ fontSize: 14 }} mt={2}>
              Stop Info:{" "}
            </Typography>
            <Grid container spacing={2} sx={{ pt: 1 }}>
              <Grid item xs={6}>
                <TextFieldLabel title="Stop Name" required />
                <CustomTextField
                  // disabled={stopData?._id ? true : false}
                  fullWidth
                  size="small"
                  placeholder="Enter Stop Name"
                  name="stopName"
                  type="text"
                  onChange={handleChange}
                  value={values.stopName}
                  error={touched.stopName && Boolean(errors.stopName)}
                  helperText={touched.stopName && errors.stopName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="Enter Distance" required />
                <CustomTextField
                  fullWidth
                  size="small"
                  name="distance"
                  placeholder="Enter Distance (KM)"
                  onChange={handleChange}
                  value={values.distance}
                  error={touched.distance && Boolean(errors.distance)}
                  helperText={touched.distance && errors.distance}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="Enter Priority" required />
                <CustomTextField
                  fullWidth
                  size="small"
                  name="priority"
                  placeholder="Priority"
                  onChange={handleChange}
                  value={values.priority}
                  error={touched.priority && Boolean(errors.priority)}
                  helperText={touched.priority && errors.priority}
                />
              </Grid>
              {/* <Grid item xs={6}>
                <TextFieldLabel title="Stop Duration" />
                <CustomTextField
                  // disabled={stopData?._id ? true : false}
                  fullWidth
                  size="small"
                  placeholder="Enter Stop Duration"
                  name="duration"
                  type="number"
                  onChange={handleChange}
                  value={values.duration}
                  error={touched.duration && Boolean(errors.duration)}
                  helperText={touched.duration && errors.duration}
                />
              </Grid> */}
            </Grid>
            <Box sx={{ mt: 2 }} />
          </form>
        </DialogContent>
        <DialogActions>
          {stopData?._id ? (
            <LoadingButton
              form="stopDetaiForm"
              type="submit"
              loading={updateStopLoading}
              variant="contained"
              color="secondary"
              sx={{ fontSize: "14px", textTransform: "capitalize", px: 3 }}
            >
              Update
            </LoadingButton>
          ) : (
            <LoadingButton
              type="submit"
              form="stopDetaiForm"
              loading={addStopLoading}
              color="secondary"
              variant="contained"
              sx={{ fontSize: "14px", textTransform: "capitalize", px: 3 }}
            >
              Submit
            </LoadingButton>
          )}
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default AddStopName;
