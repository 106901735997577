import { schoolTokenAxios } from "../axios";

const SCHOOL_BASE = process.env.REACT_APP_SCHOOL_BASE;
let transport = `${SCHOOL_BASE}/transport`;
const transportV2 = `${SCHOOL_BASE}/transportV2`;

export const updateTransportFineRate = (body) => {
  return schoolTokenAxios.put(`${transport}/updateTransportFineRate`, body);
};

export const getTransportFineRate = (params) => {
  return schoolTokenAxios.get(`${transport}/getTransportFineRate`, {
    params,
  });
};

export const addTransportDetail = (payload) => {
  return schoolTokenAxios.post(`${transportV2}/addTransport`, payload);
};
export const updateTransportDetail = (payload) => {
  return schoolTokenAxios.put(`${transportV2}/updateTransportDetail`, payload);
};

export const getTransport = (params) => {
  return schoolTokenAxios.get(`${transportV2}/getTransport`, {
    params: {
      ...params,
    },
  });
};

export const getAllTransportLog = (params) => {
  return schoolTokenAxios.get(`${transportV2}/getAllTransportLog`, {
    params: {
      ...params,
    },
  });
};

export const getSeatArrangements = (params) => {
  return schoolTokenAxios.get(`${transportV2}/getSeatArrangementByTransport`, {
    params: {
      ...params,
    },
  });
};

export const addSeatsToVehicle = (payload) => {
  return schoolTokenAxios.post(`${transportV2}/addSeatInVehicle`, payload);
};

export const updateSeatAllotment = (payload) => {
  return schoolTokenAxios.put(`${transportV2}/updateSeatAllotment`, payload);
};

export const deleteSeatFromVehicle = (payload) => {
  return schoolTokenAxios.put(`${transportV2}/deleteSeat`, payload);
};

export const replaceVehicle = (payload) => {
  return schoolTokenAxios.put(`${transportV2}/replaceVehicleInRoute`, payload);
};

export const getStudents = (params) => {
  return schoolTokenAxios.get(`${SCHOOL_BASE}/student/getStudents1`, {
    params: {
      ...params,
    },
  });
};

// route services
export const getAllRouteList = (params) => {
  return schoolTokenAxios.get(`${transportV2}/getAllRouteList`, {
    params: {
      ...params,
    },
  });
};

export const addTransportRoute = (payload) => {
  return schoolTokenAxios.post(`${transportV2}/addTransportRoute`, payload);
};

export const updateTransportRoute = (payload) => {
  return schoolTokenAxios.put(`${transportV2}/updateTransportRoute`, payload);
};

export const updateStatusOfTransport = (payload) => {
  return schoolTokenAxios.put(
    `${transportV2}/updateStatusOfTransport`,
    payload
  );
};

export const addTransportStop = (payload) => {
  return schoolTokenAxios.post(`${transportV2}/addStopInRange`, payload);
};

export const updateTransportStop = (payload) => {
  return schoolTokenAxios.put(`${transportV2}/updateStopInRange`, payload);
};

export const deleteStopFromRange = (params) => {
  return schoolTokenAxios.delete(
    `${transportV2}/deleteStopFromRange/${params}`
  );
};

export const addRangeInRoute = (payload) => {
  return schoolTokenAxios.post(`${transportV2}/addRangeInRoute`, payload);
};

export const updateRangeInRoute = (payload) => {
  return schoolTokenAxios.put(`${transportV2}/updateRangeInRoute`, payload);
};

// fee structure apis

export const getTransportFeeStructure = (params) => {
  return schoolTokenAxios.get(`${transportV2}/getTransportationFeeStructure`, {
    params: {
      ...params,
    },
  });
};

export const addTransportFeeStructure = (payload) => {
  return schoolTokenAxios.post(`${transportV2}/addTransportFee`, payload);
};

export const getRangeDetail = (params) => {
  return schoolTokenAxios.get(`${transportV2}/getRangeDetail`, {
    params: {
      ...params,
    },
  });
};

export const getStops = (params) => {
  return schoolTokenAxios.get(`${transportV2}/getStop`, {
    params: {
      ...params,
    },
  });
};

export const linkTransportFeeWithRange = (payload) => {
  return schoolTokenAxios.put(
    `${transportV2}/linkTransportFeeWithRange`,
    payload
  );
};

export const deleteRangeFromFee = (payload) => {
  return schoolTokenAxios.put(
    `${transportV2}/deLinkTransportFeeWithRange`,
    payload
  );
};

//getStudentTransportMapDetail
export const getStudentTransportMapDetail = (params) => {
  return schoolTokenAxios.get(`${transportV2}/getStudentTransportMapDetail`, {
    params: {
      ...params,
    },
  });
};

//getTransportationFeeStructure
export const getTransportationFeeStructure = (params) => {
  return schoolTokenAxios.get(`${transportV2}/getTransportationFeeStructure`, {
    params: {
      ...params,
    },
  });
};

//updateTransportInfoInStudentProfile
export const updateTransportInfoInStudentProfile = (payload) => {
  return schoolTokenAxios.put(
    `${transportV2}/updateTransportInfoInStudentProfile`,
    payload
  );
};

//getTransportFeeStructureByRange
export const getTransportFeeStructureByRange = (params) => {
  return schoolTokenAxios.get(
    `${transportV2}/getTransportFeeStructureByRange`,
    {
      params: {
        ...params,
      },
    }
  );
};

//deleteTransportStudentMap
export const deleteTransportStudentMap = (payload) => {
  return schoolTokenAxios.put(
    `${transportV2}/deleteTransportStudentMap`,
    payload
  );
};

export const deleteTransportRoute = (params) => {
  return schoolTokenAxios.delete(
    `${transportV2}/deleteTransportRoute/${params}`
  );
};

//updateStopPriority
export const updateStopPriority = (payload) => {
  return schoolTokenAxios.put(`${transportV2}/updateStopPriority`, payload);
};

//deleteTransportFeeStructure
export const deleteTransportFeeStructure = (params) => {
  return schoolTokenAxios.delete(
    `${transportV2}/deleteTransportFeeStructure/${params}`
  );
};
