import { LinearProgress } from "@mui/material";
import React from "react";

const CustomLinearProgress = ({ loading = false, color = "primary" }) => {
  return loading ? (
    <LinearProgress
      color={color}
      sx={{
        width: "100%",
      }}
    />
  ) : null;
};

export default CustomLinearProgress;
