import React from "react";
import { useMutation } from "react-query";
import {
  DialogContent,
  DialogActions,
  Grid,
  Box,
  Typography,
  MenuItem,
  Stack,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import CustomLoader from "../../CustomLoader";
import TextFieldLabel from "../../TextFieldLabel";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import { toast } from "react-toastify";
import * as yup from "yup";
import { queryClient } from "App";
import { useFormik } from "formik";
import CommonDialog from "../CommonDialog";
import {
  addRangeInRoute,
  updateRangeInRoute,
} from "app/services/schoolService/transport.service";
import { MenuProps } from "app/utils/helper";
import { useSelector } from "react-redux";
import { selectActiveSession } from "app/modules/schools/selectors";

const validationSchema = yup.object({
  fromDistance: yup
    .number("Please enter only numbers")
    .required("This is required"),
  toDistance: yup
    .number("Please enter only numbers")
    .required("This is required"),
});
const AddDistanceRange = ({
  open = false,
  rangeData = {},
  routeData = {},
  vehicleList = [],
  onClose = () => {},
  refetch = () => {},
}) => {
  const selectedSession = useSelector(selectActiveSession);
  const isEdit = rangeData?._id ? true : false;
  const { values, setValues, errors, touched, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        routerDocId: routeData._id,
        rangeCode: rangeData?.rangeCode,
        transportDocId: rangeData?.transportDocId?._id,
        fromDistance: rangeData?.fromDistance,
        fromStopName: rangeData?.fromStopName,
        toDistance: rangeData?.toDistance,
        toStopName: rangeData?.toStopName,
      },
      validationSchema,
      onSubmit: (formValues) => {
        // if docId present meaning edit

        if (isEdit) {
          const body = {
            rangeDocId: rangeData?._id,
            routeDocId: formValues?.routerDocId,
            rangeCode: formValues?.rangeCode,
            transportDocId: formValues?.transportDocId,
            fromDistance: formValues?.fromDistance,
            toDistance: formValues?.toDistance,
            fromStopName: formValues?.fromStopName,
            toStopName: formValues?.toStopName,
            session: selectedSession,
          };
          updateRangeMutate(body);
        } else {
          const body = {
            session: selectedSession,
            routeDocId: formValues?.routerDocId,
            rangeCode: formValues?.rangeCode,
            transportDocId: formValues?.transportDocId,
            fromDistance: formValues?.fromDistance,
            toDistance: formValues?.toDistance,
            fromStopName: formValues?.fromStopName,
            toStopName: formValues?.toStopName,
          };
          addRangeMutate(body);
        }
      },
    });
  // update Stop
  const {
    mutate: updateRangeMutate,
    isLoading: updateStopLoading,
    data: updaterangeData,
  } = useMutation("updateRange", updateRangeInRoute, {
    onSuccess: (tranportdetaildata) => {
      toast.success(updaterangeData?.data?.message);
      onClose();
    },
    onError: (updateStopErrorResponse) => {
      toast.error(updateStopErrorResponse?.response.data?.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries(["getAllStop"]);
      queryClient.invalidateQueries(["getRouteDataByDocId"]);
      queryClient.invalidateQueries(["getAllRouteList"]);
      queryClient.invalidateQueries(["getAllRoute"]);
    },
  });
  // add route
  const { mutate: addRangeMutate, isLoading: addStopLoading } = useMutation(
    "addRange",
    addRangeInRoute,
    {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        onClose();
      },
      onError: (addStopErrorRespose) => {
        toast.error(addStopErrorRespose?.response?.data?.message);
      },
      onSettled: () => {
        queryClient.invalidateQueries(["getAllStop"]);
        queryClient.invalidateQueries(["getAllRouteList"]);
        queryClient.invalidateQueries(["getAllRoute"]);
        queryClient.invalidateQueries(["getRouteDataByDocId"]);
      },
    }
  );

  return (
    <>
      <CustomLoader show={updateStopLoading || addStopLoading} />

      <CommonDialog
        title={`${rangeData?._id ? "Update" : "Add"} Distance Range`}
        open={open}
        onClose={onClose}
        minWidth="600px"
      >
        <DialogContent>
          <Typography>Route Info: </Typography>
          <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
            {routeData?.routeNumber} : {routeData?.routeCode} :{" "}
            {routeData?.routeName}
          </Typography>
          <form onSubmit={handleSubmit} id="stopDetaiForm">
            <Grid container spacing={2} mt={2} sx={{ pt: 1 }}>
              <Grid item xs={6}>
                <TextFieldLabel title="Range Code" />
                <CustomTextField
                  fullWidth
                  size="small"
                  name="rangeCode"
                  placeholder="Enter Range Code"
                  type="text"
                  onChange={handleChange}
                  value={values.rangeCode}
                  error={touched.rangeCode && Boolean(errors.rangeCode)}
                  helperText={touched.rangeCode && errors.rangeCode}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="Vehicle Number" />
                <CustomSelectBox
                  fullWidth
                  size="small"
                  placeholder="Select Vehicle"
                  value={values.transportDocId}
                  onChange={handleChange}
                  name="transportDocId"
                  displayEmpty
                  MenuProps={MenuProps}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  {vehicleList?.map((item, index) => (
                    <MenuItem value={item?._id} key={index}>
                      <Stack direction={"row"} spacing={1}>
                        {item?.vehicleNumber}
                        {item?.vehicleNickName && (
                          <Typography
                            sx={{
                              fontSize: 12,
                              color: "secondary.main",
                              ml: 1,
                            }}
                          >
                            {`(${item?.vehicleNickName})`}
                          </Typography>
                        )}
                      </Stack>
                    </MenuItem>
                  ))}
                </CustomSelectBox>
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="From (KM)" required />
                <CustomTextField
                  fullWidth
                  size="small"
                  name="fromDistance"
                  placeholder="From"
                  onChange={handleChange}
                  value={values.fromDistance}
                  error={touched.fromDistance && Boolean(errors.fromDistance)}
                  helperText={touched.fromDistance && errors.fromDistance}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="From (Stop Name)" />
                <CustomTextField
                  fullWidth
                  size="small"
                  placeholder="Enter Stop Name"
                  name="fromStopName"
                  type="text"
                  onChange={handleChange}
                  value={values.fromStopName}
                  error={touched.fromStopName && Boolean(errors.fromStopName)}
                  helperText={touched.fromStopName && errors.fromStopName}
                />
              </Grid>

              <Grid item xs={6}>
                <TextFieldLabel title="To (KM)" required />
                <CustomTextField
                  fullWidth
                  size="small"
                  name="toDistance"
                  placeholder="From"
                  onChange={handleChange}
                  value={values.toDistance}
                  error={touched.toDistance && Boolean(errors.toDistance)}
                  helperText={touched.toDistance && errors.toDistance}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="To (Stop Name)" />
                <CustomTextField
                  fullWidth
                  size="small"
                  placeholder="Enter Stop Name"
                  name="toStopName"
                  type="text"
                  onChange={handleChange}
                  value={values.toStopName}
                  error={touched.toStopName && Boolean(errors.toStopName)}
                  helperText={touched.toStopName && errors.toStopName}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: 2 }} />
          </form>
        </DialogContent>
        <DialogActions>
          {rangeData?._id ? (
            <LoadingButton
              form="stopDetaiForm"
              type="submit"
              loading={updateStopLoading}
              variant="contained"
              color="secondary"
              sx={{ fontSize: "14px", textTransform: "capitalize", px: 3 }}
            >
              Update
            </LoadingButton>
          ) : (
            <LoadingButton
              type="submit"
              form="stopDetaiForm"
              loading={addStopLoading}
              color="secondary"
              variant="contained"
              sx={{ fontSize: "14px", textTransform: "capitalize", px: 3 }}
            >
              Submit
            </LoadingButton>
          )}
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default AddDistanceRange;
