import { Add, East, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  MenuItem,
  DialogActions,
  colors,
  useMediaQuery,
} from "@mui/material";
import React, { useMemo, useRef, useState } from "react";
import { Container } from "@mui/material";
import { useQuery, useMutation } from "react-query";
import { queryClient } from "App";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";

import CommonTable1 from "app/components/Tables/CommonTable1";
import DialogHeader from "app/components/common/DialogHeader";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import { MenuProps } from "app/utils/helper";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import ExportDialog from "app/components/common/exportDialog";
import {
  deleteTransportRoute,
  getAllRouteList,
  getTransport,
  replaceVehicle,
} from "app/services/schoolService/transport.service";
import ViewRouteDetail from "app/components/common/Dialogs/transport/ViewRouteDetail";
import AddRouteDialog from "app/components/common/Dialogs/transport/AddRouteDialog";
import { useSelector } from "react-redux";
import { selectActiveSession } from "app/modules/schools/selectors";
import TableActionButtons from "app/components/common/TableActionButtons";
import DeleteDialog from "app/components/ConfirmationDialog/DeleteDialog";
import CustomLinearProgress from "app/components/common/CustomLinearProgress";

const RouteDetail = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [vehicleDialog, setVehicleDialog] = useState(false);
  const heightRef = useRef(null);
  const selectedSession = useSelector(selectActiveSession);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [values, setValues] = useState({
    routeDocId: "",
    routeData: {},
    createDialog: false,
    viewDialog: false,
    routeNumber: "",
    routeName: "",
    edit: false,
  });
  const [vehicle, setVehicle] = useState({
    current: "",
    selected: "",
    route: "",
  });
  const [openCheckBox, setOpenCheckBox] = useState(false);

  let header = [
    "Route_Number",
    "Route_Name",
    "Stop_List",
    "Assigned_Vehicle",
    "From",
  ];

  // get All Route
  const {
    data: getAllRouteData,
    isLoading: getAllRouteLoading,
    isFetching: getAllRouteFetching,
    refetch: getAllRouteListRefetch,
  } = useQuery({
    queryKey: ["getAllRoute"],
    queryFn: () =>
      getAllRouteList({
        session: selectedSession,
        partiallyDeleted: false,
      }),
    onSuccess: (isSuccess) => {},
  });

  // get All Transport
  const { refetch: getAllTransportRefetch, data: getAllTransportData } =
    useQuery({
      queryKey: ["getTransport"],
      queryFn: () => getTransport({ status: "ACTIVE" }),
      onSuccess: (isSuccess) => {},
    });

  const { mutate: replaceVehicleMutate, isLoading: replaceVehicleLoading } =
    useMutation(replaceVehicle, {
      onSuccess: (res) => {
        toast.success(res?.data?.message);
        queryClient.invalidateQueries({ queryKey: "getAllRoute" });
        setVehicleDialog(false);
        setVehicle((prev) => ({ route: "", selected: "", current: "" }));
      },
      onError: (err) => toast.error(err?.response?.data?.message),
    });
  const {
    mutate: deleteTransportRouteMutate,
    isLoading: deleteTransportRouteLoading,
  } = useMutation(deleteTransportRoute, {
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      queryClient.invalidateQueries({ queryKey: "getAllRoute" });
      setDeleteDialog(false);
      setValues((prev) => ({
        ...prev,
        routeDocId: "",
        routeData: {},
      }));
    },
    onError: (err) => toast.error(err?.response?.data?.message),
  });

  let exportArray = getAllRouteData?.data?.list.map((item) => {
    return {
      Route_Number: item?.routeNumber ?? "--",
      Route_Name: item?.routeName ?? "--",
      Stop_List:
        item?.stopList?.map((name) => name?.stopName).join(",") ?? "--",
      Assigned_Vehicle:
        item?.assignedVehicle
          ?.map((vehicleNumber) => vehicleNumber)
          .join(",") ?? "--",
      From: item?.from ?? "--",
    };
  });

  const columns = useMemo(() => {
    return [
      {
        header: "Sr.No",
        Cell: ({ cell }) => (
          <Typography>
            {parseInt(cell.row.id) + 1 < 10
              ? `0${parseInt(cell.row.id) + 1}`
              : parseInt(cell.row.id) + 1}
          </Typography>
        ),
        maxSize: isMobile ? 20 : 30,
      },
      {
        header: "Route Name",
        accessorKey: "routeName",
        size: isMobile ? 30 : 60,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography
            onClick={() =>
              setValues((prev) => ({
                ...prev,
                viewDialog: true,
                routeData: original,
              }))
            }
            sx={{
              color: "#2789FD",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            {original?.routeName}
          </Typography>
        ),
      },
      {
        header: "Stops",
        accessorKey: "stopList",
        size: isMobile ? 30 : 60,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Stack direction="row" flexWrap="wrap">
              {original?.stopList?.map((stop, index) => (
                <Typography>
                  {stop?.stopName}
                  {index + 1 < original?.stopList?.length && (
                    <>
                      <East
                        sx={{
                          fontSize: isMobile ? 10 : 14,
                          color: colors.blue[500],
                        }}
                      />
                    </>
                  )}
                </Typography>
              ))}
            </Stack>
          );
        },
      },
      {
        header: "Assigned Vehicle",
        size: isMobile ? 60 : 80,
        accessorKey: "assignedVehicle",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Box>
              {original?.assignedVehicle?.map(({ vehicleNumber }, index) =>
                vehicleNumber ? (
                  <Box
                    key={index}
                    sx={{
                      backgroundColor: colors.green[400],
                      py: 0.5,
                      mt: 0.5,
                      borderRadius: "5px",
                      width: isMobile ? "80px" : "120px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setVehicle((prev) => ({
                        ...prev,
                        route: original?._id,
                        current: vehicleNumber,
                      }));
                      setVehicleDialog(true);
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "uppercase",
                        color: "#fff",
                        textAlign: "center",
                        fontSize: isMobile ? 10 : 14,
                      }}
                    >
                      {vehicleNumber}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    key={index}
                    sx={{
                      backgroundColor: colors.red[400],
                      py: 0.5,
                      mt: 0.5,
                      borderRadius: "5px",
                      width: isMobile ? "80px" : "120px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setVehicle((prev) => ({
                        ...prev,
                        route: original?._id,
                        current: vehicleNumber,
                      }));
                      setVehicleDialog(true);
                    }}
                  >
                    <Typography
                      sx={{
                        textTransform: "uppercase",
                        color: "#fff",
                        textAlign: "center",
                        fontSize: isMobile ? 10 : 14,
                      }}
                    >
                      {vehicleNumber}
                    </Typography>
                  </Box>
                )
              )}
            </Box>
          );
        },
      },
      {
        header: "Actions",
        Cell: ({ cell }) => {
          return (
            <TableActionButtons
              showDelete={true}
              showEdit={true}
              handleEdit={() => {
                setValues((prev) => ({
                  ...prev,
                  edit: true,
                  routeData: cell?.row?.original,
                }));
              }}
              handleDelete={() => {
                setDeleteDialog(true);
                setValues((prev) => ({
                  ...prev,
                  routeDocId: cell?.row?.original?._id,
                  routeData: cell?.row?.original,
                }));
              }}
            />
          );
        },
        size: isMobile ? 20 : 40,
      },
    ];
  }, []);

  return (
    <>
      <ExportDialog
        open={openCheckBox}
        handleClose={() => setOpenCheckBox(false)}
        header={header}
        exportArray={exportArray}
        fileName="Route_Detail"
      />

      {/* create Dialog */}
      {values.createDialog && (
        <AddRouteDialog
          open={values.createDialog}
          onClose={() =>
            setValues((prev) => ({
              ...prev,
              createDialog: false,
            }))
          }
          refetch={() => getAllRouteListRefetch()}
        />
      )}

      {/* edit dialog */}
      {values.edit && (
        <AddRouteDialog
          open={values.edit}
          routeData={values.routeData}
          onClose={() =>
            setValues((prev) => ({
              ...prev,
              edit: false,
              routeData: {},
            }))
          }
          refetch={() => getAllRouteListRefetch()}
        />
      )}

      <DeleteDialog
        handleClose={() => {
          setDeleteDialog(false);
          setValues((prev) => ({
            ...prev,
            routeDocId: "",
            routeData: {},
          }));
        }}
        handleDelete={() => {
          deleteTransportRouteMutate(values.routeDocId);
        }}
        loading={deleteTransportRouteLoading}
        open={deleteDialog}
        title={`${values.routeData?.routeName} - ${values.routeData?.routeNumber} route`}
      />

      {/* replace vehicle dialog */}
      <Dialog
        onClose={() => {
          setVehicleDialog(false);
          setVehicle((prev) => ({ route: "", selected: "", current: "" }));
        }}
        open={vehicleDialog}
        PaperProps={{
          sx: { minWidth: isMobile ? 300 : 500 },
        }}
      >
        <DialogTitle sx={{ p: "0 !important" }}>
          <DialogHeader
            title="Want to replace ?"
            handleClose={() => {
              setVehicleDialog(false);
              setVehicle((prev) => ({ route: "", selected: "", current: "" }));
            }}
          />
        </DialogTitle>
        <DialogContent>
          <Stack direction="row" gap={2} mt={2}>
            <Stack flex={1}>
              <TextFieldLabel title="Current Vehicle" />
              <CustomTextField disabled value={vehicle?.current} />
            </Stack>
            <Stack flex={1}>
              <TextFieldLabel title="New Vehicle" />
              <CustomSelectBox
                MenuProps={MenuProps}
                displayEmpty
                value={vehicle.selected}
                onChange={(e) => {
                  setVehicle((prev) => ({ ...prev, selected: e.target.value }));
                }}
              >
                <MenuItem disabled value="">
                  Select
                </MenuItem>
                {getAllTransportData?.data?.list?.map((item) => (
                  <MenuItem value={item?.vehicleNumber}>
                    {item?.vehicleNumber}
                  </MenuItem>
                ))}
              </CustomSelectBox>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={replaceVehicleLoading}
            variant="contained"
            color="secondary"
            onClick={() =>
              replaceVehicleMutate({
                routeDocId: vehicle.route,
                currentVehicleNumber: vehicle.current,
                newVehicleNumber: vehicle.selected,
              })
            }
          >
            Replace
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Container
        maxWidth="xl"
        disableGutters={isMobile ? true : false}
        sx={{ display: "flex", flexDirection: "column", height: "100%" }}
      >
        <Stack
          mt={isMobile && 2}
          direction="row"
          justifyContent="flex-end"
          gap={2}
        >
          <Button
            onClick={() =>
              setValues((prev) => ({ ...prev, createDialog: true }))
            }
            variant="contained"
            color="secondary"
            startIcon={<Add />}
            sx={{ fontSize: isMobile ? 12 : 16, textTransform: "capitalize" }}
          >
            Add New Route
          </Button>
          <ExportButtonGroup
            handleClickExcel={() => setOpenCheckBox(true)}
            showPdf={false}
          />
        </Stack>

        {/* <Table columns={columns} data={getAllRouteData?.data?.list} /> */}
        <Box sx={{ mt: 2, height: "100%" }} ref={heightRef}>
          {getAllRouteLoading || getAllRouteFetching ? (
            <CustomLinearProgress
              loading={getAllRouteLoading || getAllRouteFetching}
            />
          ) : null}

          <CommonTable1
            columns={columns}
            data={getAllRouteData?.data?.list}
            maxHeight={`${heightRef?.current?.clientHeight - 70}px`}
          />
        </Box>
      </Container>

      {/* View Route Detail Dialog */}
      {values.viewDialog && (
        <ViewRouteDetail
          open={values.viewDialog}
          vehicleList={getAllTransportData?.data?.list}
          routeData={values.routeData}
          onClose={() =>
            setValues((prev) => ({ ...prev, routeData: {}, viewDialog: false }))
          }
          refetch={() => {
            getAllRouteListRefetch();
            getAllTransportRefetch();
          }}
          // status={"IN_ACTIVE"}
        />
      )}
    </>
  );
};

export default RouteDetail;
