import { FormatFullName } from "app/utils/helper";
import { schoolTokenAxios } from "../axios";

const SCHOOL_BASE = process.env.REACT_APP_SCHOOL_BASE;

let student_url = `${SCHOOL_BASE}/student`;

export const getStudentProfile = (params) => {
  return schoolTokenAxios.get(`${student_url}/getStudentProfile`, {
    params: params,
  });
};
export const getDistinctHouseInfo = (params) => {
  return schoolTokenAxios.get(`${student_url}/getDistinctHouseInfo`, {
    params: params,
  });
};
export const getDeletedStudent = (params) => {
  return schoolTokenAxios.get(`${student_url}/getDeletedStudent`, {
    params: params,
  });
};

export const getStudentsByDocIdList = (body) => {
  return schoolTokenAxios.put(`${student_url}/getStudentsByDocIdList`, body);
};

export const bulkUpdateStudentAcademicInfo = (body) => {
  return schoolTokenAxios.put(
    `${student_url}/bulkUpdateStudentAcademicInfo`,
    body
  );
};

export const getTransportForStudent = (params) => {
  return schoolTokenAxios.get(`${student_url}/getTransportForStudent`, {
    params: params,
  });
};

export const getTransportForStudent2 = (params) => {
  return schoolTokenAxios.get(`${student_url}/getTransportForStudent2`, {
    params: params,
  });
};

export const getAllStudentNumber = (params) => {
  return schoolTokenAxios.get(`${student_url}/getAllStudentNumber`, {
    params: params,
  });
};

export const getClassVsStudent = (params) => {
  return schoolTokenAxios.get(`${student_url}/getClassVsStudent`, {
    params: params,
  });
};

export const getAllNewAdmissions = (params) => {
  return schoolTokenAxios.get(`${student_url}/getAllNewAdmissions`, {
    params: params,
  });
};

export const getStudentByAdhar = (id) => {
  return schoolTokenAxios.get(`${student_url}/getStudentByAdhar/${id}`);
};

export const getStudentHealthInfo = (params) => {
  return schoolTokenAxios.get(`${student_url}/getStudentHealthInfo`, {
    params: params,
  });
};

export const updateStudentPersonalInfoBySchool = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/updateStudentPersonalInfoBySchool`,
    payload
  );
};

export const retrieveMigratedStudent = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/retrieveMigratedStudent`,
    payload
  );
};
export const updateProfileVerificationStatus = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/updateProfileVerificationStatus`,
    payload
  );
};
export const deleteStudentPartially = (payload) => {
  return schoolTokenAxios.put(`${student_url}/deleteStudentPartially`, payload);
};
export const retrievePartillyDeletedStudent = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/retrievePartillyDeletedStudent`,
    payload
  );
};

export const updateParentInfo = (payload) => {
  return schoolTokenAxios.put(`${student_url}/updateParentInfo`, payload);
};

export const updateAddressInfo = (payload) => {
  return schoolTokenAxios.put(`${student_url}/updateAddressInfo`, payload);
};

export const updateBankInfo = (payload) => {
  return schoolTokenAxios.put(`${student_url}/updateBankInfo`, payload);
};

export const updateStudentHealthInfo = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/updateStudentHealthInfo`,
    payload
  );
};

export const changeStudentProfilePic = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/changeStudentProfilePic`,
    payload
  );
};

export const updateHostelInfo = (payload) => {
  return schoolTokenAxios.put(`${student_url}/updateHostelInfo`, payload);
};

export const updateHouseInfo = (payload) => {
  return schoolTokenAxios.put(`${student_url}/updateHouseInfo`, payload);
};
//********** */ Document Uploading and Deletion***********

export const addDocumentForStudent = (payload) => {
  return schoolTokenAxios.post(`${student_url}/addDocumentForStudent`, payload);
};

export const uploadDocumentForStudent = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/uploadDocumentForStudent`,
    payload
  );
};

export const updateStudentFeeRelaxation = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/updateStudentFeeRelaxation`,
    payload
  );
};

export const getAllDocumentByStudentDocId = (body) => {
  return schoolTokenAxios.get(
    `${student_url}/getAllDocumentByStudentDocId?studentDocId=${body?.studentDocId}`,
    {
      body,
    }
  );
};

export const deleteDocument = (id) => {
  return schoolTokenAxios.delete(`${student_url}/deleteDocument/${id}`);
};

// *********************************************************
// Admission Approval Module Strats From Here
// *********************************************************
export const getStudentAdmissionRequests = (params) => {
  return schoolTokenAxios.get(`${student_url}/getStudentAdmissionRequests`, {
    params: params,
  });
};

export const getStudentProfileByAdmissionRequestDocId = (params) => {
  return schoolTokenAxios.get(
    `${student_url}/getStudentProfileByAdmissionRequestDocId`,
    {
      params: params,
    }
  );
};

export const rejectAdmissionRequest = (admissionRequestDocId) => {
  return schoolTokenAxios.get(
    `${student_url}/rejectAdmissionRequest/${admissionRequestDocId}`
  );
};

export const admitStudentAdmissionApproval = (admissionRequestDocId) => {
  return schoolTokenAxios.put(
    `${student_url}/admitStudentAdmissionApproval/${admissionRequestDocId}`
  );
};

export const updateAcademicInfo = (payload) => {
  return schoolTokenAxios.put(`${student_url}/updateAcademicInfo`, payload);
};

export const updateSRInfo = (payload) => {
  return schoolTokenAxios.put(`${student_url}/updateSRInfo`, payload);
};

export const updateStudentClassInfo = (payload) => {
  return schoolTokenAxios.put(`${student_url}/updateStudentClassInfo`, payload);
};

// *********************************************************
// Students Module Strats From Here
// *********************************************************

export const checkAdharExist = (adharNumber) => {
  return schoolTokenAxios.get(`${student_url}/checkAdharExist/${adharNumber}`);
};

export const checkAdharExistInSameSchool = (adharNumber) => {
  return schoolTokenAxios.get(
    `${student_url}/checkAdharExistInSameSchool/${adharNumber}`
  );
};

export const getWholeStudentsName = (params) => {
  return schoolTokenAxios.get(`${student_url}/getWholeStudentsName`, {
    params: params,
  });
};

export const getStudents1 = (params) => {
  return schoolTokenAxios.get(`${student_url}/getStudents1`, {
    params: params,
  });
};

export const getStudentsForGivenList = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/getStudentsForGivenList`,
    payload
  );
};

export const getRollNumberInRequestedOrder = (params) => {
  return schoolTokenAxios.get(`${student_url}/getRollNumberInRequestedOrder`, {
    params: params,
  });
};

export const updateRollNumberInRequestedOrder = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/updateRollNumberInRequestedOrder`,
    payload
  );
};

export const getLastAdmissionCount = (params) => {
  return schoolTokenAxios.get(`${student_url}/getLastAdmissionCount`, {
    params: params,
  });
};
export const getStudentReportForTable = (params) => {
  return schoolTokenAxios.get(`${student_url}/getStudentReportForTable`, {
    params: params,
  });
};

// Student Profile Related API

export const updateTransportInfoInStudentProfile = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/updateTransportInfoInStudentProfile`,
    payload
  );
};
// *********************************************************
// Transit Module Strats From Here
// *********************************************************
//Promotion Route Starts From Here

export const getStudentForPromotionOrMigration = (params) => {
  return schoolTokenAxios.get(
    `${student_url}/getStudentForPromotionOrMigration`,
    {
      params: params,
    }
  );
};

export const promoteStudent = (payload) => {
  return schoolTokenAxios.put(`${student_url}/promoteStudent`, payload);
};

export const getStudentDataForMigrate = (params) => {
  return schoolTokenAxios.get(`${student_url}/getStudentDataForMigrate`, {
    params: params,
  });
};

export const migrateStudent = (payload) => {
  return schoolTokenAxios.put(`${student_url}/migrateStudent`, payload);
};

export const getAlumni = (params) => {
  return schoolTokenAxios.get(`${student_url}/getAlumni`, {
    params: params,
  });
};

export const updateTransferCertificate = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/updateTransferCertificate`,
    payload
  );
};

export const getTransferCertificate = (params) => {
  return schoolTokenAxios.get(`${student_url}/getTransferCertificate`, {
    params: params,
  });
};

// Version 3 Starts Here

// registerFreshStudent

export const registerFreshStudent = (payload) => {
  return schoolTokenAxios.post(`${student_url}/registerFreshStudent`, payload);
};

export const suspendStudent = (payload) => {
  return schoolTokenAxios.put(`${student_url}/suspendStudent`, payload);
};
export const inActivateStudent = (payload) => {
  return schoolTokenAxios.put(`${student_url}/inActivateStudent`, payload);
};
export const activateStudent = (payload) => {
  return schoolTokenAxios.put(`${student_url}/activateStudent`, payload);
};

export const retrieveSuspendedStudent = (payload) => {
  return schoolTokenAxios.put(
    `${student_url}/retrieveSuspendedStudent`,
    payload
  );
};

// export const addStudentByExcel = (payload) => {
//   return schoolTokenAxios.post(`${student_url}/addStudentByExcel`, payload);
// };

export const addStudentByExcel = (file) => {
  const formData = new FormData();
  formData.append("studentData", file);
  for (var pair of formData.entries()) {
  }

  return schoolTokenAxios.post(`${student_url}/addStudentByExcel`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/**********BULK ADMISSION API*************************/

export const getBulkAdmissionForStudentJsonFormat = () => {
  return schoolTokenAxios.get(
    `${student_url}/getBulkAdmissionForStudentJsonFormat`
  );
};
export const getBulkUploadStudenttransportInfoJson = () => {
  return schoolTokenAxios.get(
    `${student_url}/getBulkUploadStudenttransportInfoJson`
  );
};

export const bulkRegisterFreshStudent = async (payload, chunkSize = 100) => {
  const { session, students } = payload;

  const chunkedStudents = [];
  for (let i = 0; i < students.length; i += chunkSize) {
    chunkedStudents.push(students.slice(i, i + chunkSize));
  }

  const responses = [];
  for (const chunk of chunkedStudents) {
    try {
      const response = await schoolTokenAxios.post(
        `${student_url}/bulkRegisterFreshStudent`,
        { session, students: chunk }
      );
      responses.push(response?.data); // Store only the response data
    } catch (error) {
      console.error("Error sending chunk:", error);
      // Handle error for the chunk
      responses.push({
        success: false,
        message: `Error from Student ${chunk[0].firstName} to ${
          chunk[chunk.length - 1]?.firstName
        }: ${error.response?.data?.message || error.message} `,
        data: null,
      });
      // Optionally, you can break the loop or continue based on your needs
      // break; // Uncomment this line to stop processing on error
      // continue; // Uncomment this line to skip to the next chunk
      // You can also choose to throw an error or return a specific response
    }
  }

  // Return a single aggregated response
  return {
    success: true,
    message: "All chunks processed successfully",
    data: responses, // Include all responses
  };
};

export const bulkUpdateStudentTransportInfo = (payload) => {
  return schoolTokenAxios.post(
    `${student_url}/bulkUpdateStudentTransportInfo`,
    payload
  );
};
