import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { DialogContent, Grid, Stack, Button, IconButton } from "@mui/material";
import CommonDialog from "../CommonDialog";
import TextFieldLabel from "../../TextFieldLabel";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import {
  deleteStopFromRange,
  getAllRouteList,
  updateStopPriority,
} from "app/services/schoolService/transport.service";

import AddStopName from "./AddStopNameDialog";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import AddDistanceRange from "./AddDistanceRange";
import AddRouteDialog from "./AddRouteDialog";
import { toast } from "react-toastify";
import { queryClient } from "App";
import { useSelector } from "react-redux";
import { selectActiveSession } from "app/modules/schools/selectors";
import TableActionButtons from "../../TableActionButtons";
import DeleteDialog from "app/components/ConfirmationDialog/DeleteDialog";
import LinkFeeStructureDialog from "./LinkFeeStructureDialog";
import CustomLinearProgress from "../../CustomLinearProgress";

const ViewRouteDetail = ({
  open = false,
  routeData = {},
  vehicleList = [],
  onClose = () => {},
  refetch = () => {},
}) => {
  const selectedSession = useSelector(selectActiveSession);
  const [state, setState] = useState({
    routeDialog: false,
    routeData: {},
    stopDialog: false,
    stopData: {},
    rangeDialog: false,
    rangeData: {},
    deleteConfirmation: false,
    linkFeeStructureDialog: false,
    selectedFeeStructure: {},
  });
  // get All Route
  const {
    isLoading: getRouteDataByDocIdLoading,
    data: getRouteData,
    refetch: routeRefetch,
  } = useQuery({
    queryKey: ["getRouteDataByDocId"],
    queryFn: () => {
      let params = {
        routeDocId: routeData._id,
        session: selectedSession,
      };
      return getAllRouteList(params);
    },
    onSuccess: (isSuccess) => {},
    enabled: routeData._id ? true : false,
  });

  const handleDeleteStop = (stopDetail) => {
    deleteStopMutate(stopDetail._id);
  };

  const { mutate: deleteStopMutate, isLoading: deleteStopLoading } =
    useMutation("deleteStop", deleteStopFromRange, {
      onSuccess: ({ data }) => {
        queryClient.invalidateQueries(["getRouteDataByDocId"]);
        queryClient.invalidateQueries(["getAllStop"]);
        refetch();
        toast.success(data?.message);
        setState((prev) => ({
          ...prev,
          deleteConfirmation: false,
          stopData: {},
        }));
      },
      onError: (error) => {
        toast.error(error.response.data?.message);
      },
    });

  const {
    mutate: updateStopPriorityMutate,
    isLoading: updateStopPriorityLoading,
  } = useMutation("updateStopPriority", updateStopPriority, {
    onSuccess: ({ data }) => {
      routeRefetch();
      toast.success(data?.message);
    },
    onError: (error) => {
      toast.error(error.response.data?.message);
    },
  });

  return (
    <>
      <CustomLinearProgress
        loading={
          getRouteDataByDocIdLoading ||
          deleteStopLoading ||
          updateStopPriorityLoading
        }
      />
      <DeleteDialog
        open={state.deleteConfirmation}
        handleClose={() =>
          setState((prev) => ({
            ...prev,
            deleteConfirmation: false,
            stopData: {},
          }))
        }
        handleDelete={() => {
          handleDeleteStop(state.stopData);
        }}
        title={`${state?.stopData?.stopName ?? ""} Stop`}
      />
      <CommonDialog
        title={"Route Detail"}
        open={open}
        onClose={onClose}
        minWidth="992px"
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <TextFieldLabel title="Route Number" />
              <CustomTextField
                disabled
                fullWidth
                size="small"
                value={getRouteData?.data?.obj?.routeNumber}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <TextFieldLabel title="Route Name" />
              <Stack
                direction={"row"}
                sx={{ alignItems: "center" }}
                spacing={1}
              >
                <CustomTextField
                  disabled
                  fullWidth
                  size="small"
                  value={getRouteData?.data?.obj?.routeName}
                />
                <TableActionButtons
                  showEdit={true}
                  handleEdit={() => {
                    setState((prev) => ({
                      ...prev,
                      routeDialog: true,
                      routeData: getRouteData?.data?.obj,
                    }));
                  }}
                />
              </Stack>
            </Grid>
          </Grid>

          <Grid container spacing={2} mt={2}>
            <Grid item sm={11} xs={12} sx={{ margin: "auto" }}>
              <TextFieldLabel title={"Stop / Pickup Name"} mb={3} />
              <Grid
                container
                spacing={2}
                sx={{
                  background: "#F6F8FC",
                  fontWeight: 500,
                  fontSize: 14,
                  borderTopLeftRadius: 16,
                  borderTopRightRadius: 16,
                  padding: 1,
                  border: "1px solid #B6C8D6",
                }}
              >
                <Grid xs={2} sx={{ paddingLeft: 2 }}>
                  Range
                </Grid>
                <Grid xs={2}>Fee Amount</Grid>
                <Grid xs={2}>Vehicle Number</Grid>
                <Grid xs={2}>Stop Name</Grid>
                <Grid xs={4}>Distance From School</Grid>
              </Grid>
              {getRouteData?.data?.obj?.rangeDetail?.map((range, index) => {
                return (
                  <Grid
                    container
                    mt={0}
                    spacing={2}
                    sx={{
                      padding: 1,
                      border: "1px solid #B6C8D6",
                      fontSize: 14,
                    }}
                    style={
                      getRouteData?.data?.obj?.rangeDetail?.length - 1 === index
                        ? {
                            borderBottomLeftRadius: 16,
                            borderBottomRightRadius: 16,
                          }
                        : {}
                    }
                  >
                    <Grid
                      xs={2}
                      sx={{
                        paddingLeft: 2,
                        cursor: "pointer",
                        color: "#2789FD",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        setState((prev) => ({
                          ...prev,
                          rangeDialog: true,
                          rangeData: range,
                        }));
                      }}
                    >
                      {range?.fromDistance + "-" + range?.toDistance + "Km"}
                    </Grid>
                    <Grid
                      xs={2}
                      sx={{
                        cursor: "pointer",
                        fontSize: 12,
                        fontWeight: 400,
                        color: "#2789FD",
                        fontStyle: "italic",
                      }}
                    >
                      {range?.transportFeeDocId?.feeAmount ? (
                        <Button
                          onClick={() => {
                            setState((prev) => ({
                              ...prev,
                              linkFeeStructureDialog: true,
                              selectedFeeStructure: range,
                            }));
                          }}
                          title="Click to Edit Fee"
                          sx={{
                            background: "none",
                            color: "#2789FD",
                            fontStyle: "italic",
                            fontSize: 14,
                            padding: 0,
                            textDecoration: "underline",
                          }}
                        >
                          {range?.transportFeeDocId?.feeAmount ?? 0}
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            setState((prev) => ({
                              ...prev,
                              linkFeeStructureDialog: true,
                              selectedFeeStructure: range,
                            }));
                          }}
                          sx={{
                            background: "none",
                            color: "#2789FD",
                            textDecoration: "underline",
                            fontStyle: "italic",
                            fontSize: 14,
                            padding: 0,
                          }}
                        >
                          + Add Fee
                        </Button>
                      )}
                    </Grid>
                    <Grid xs={2}>{range?.transportDocId?.vehicleNumber}</Grid>
                    <Grid container xs={6}>
                      {range?.stopDetail?.map((stopDetail) => {
                        return (
                          <Grid
                            container
                            sx={{
                              padding: 0.5,
                              alignItems: "center",
                              borderBottom: "0.5px solid #B6C8D6",
                              fontWeight: 500,
                            }}
                          >
                            <Grid
                              item
                              xs={5}
                              container
                              alignItems="center"
                              spacing={2}
                              p={1}
                            >
                              <IconButton
                                onClick={() => {
                                  updateStopPriorityMutate({
                                    session: selectedSession,
                                    stopDocId: stopDetail._id,
                                    rangeDocId: range._id,
                                    order: "up",
                                  });
                                }}
                                sx={{
                                  padding: 0,
                                  color: "#2789FD",
                                }}
                                disabled={
                                  range?.stopDetail?.[0]?._id ===
                                  stopDetail?._id
                                }
                                title="Priority Up"
                              >
                                <KeyboardArrowUp />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  updateStopPriorityMutate({
                                    session: selectedSession,
                                    stopDocId: stopDetail._id,
                                    rangeDocId: range._id,
                                    order: "down",
                                  });
                                }}
                                sx={{
                                  padding: 0,
                                  color: "#2789FD",
                                }}
                                title="Priority Down"
                                disabled={
                                  range?.stopDetail?.[
                                    range?.stopDetail?.length - 1
                                  ]?._id === stopDetail?._id
                                }
                              >
                                <KeyboardArrowDown />
                              </IconButton>
                              {stopDetail?.stopName}
                            </Grid>

                            <Grid xs={5}>{stopDetail?.distance}</Grid>
                            <Grid xs={2}>
                              <TableActionButtons
                                showDelete={true}
                                handleDelete={() => {
                                  setState((prev) => ({
                                    ...prev,
                                    deleteConfirmation: true,
                                    stopData: stopDetail,
                                  }));
                                }}
                                showEdit={true}
                                handleEdit={() => {
                                  setState((prev) => ({
                                    ...prev,
                                    stopDialog: true,
                                    stopData: stopDetail,
                                  }));
                                }}
                              />
                            </Grid>
                          </Grid>
                        );
                      })}
                      <Button
                        onClick={() => {
                          setState((prev) => ({
                            ...prev,
                            stopDialog: true,
                            stopData: {
                              rangeDocId: range._id,
                            },
                          }));
                        }}
                        sx={{
                          background: "none",
                          color: "#2789FD",
                          fontStyle: "italic",
                          fontSize: 14,
                        }}
                      >
                        + Add More Stop
                      </Button>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>

          <Button
            sx={{
              background: "none",
              color: "#2789FD",
              fontSize: 14,
              padding: "0.5rem 1.25rem",
            }}
            onClick={() => {
              setState((prev) => ({
                ...prev,
                rangeDialog: true,
              }));
            }}
            size="large"
          >
            + Add More Range
          </Button>
        </DialogContent>
      </CommonDialog>

      <LinkFeeStructureDialog
        open={state.linkFeeStructureDialog}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            linkFeeStructureDialog: false,
            selectedFeeStructure: {},
          }))
        }
        refetch={() => {
          routeRefetch();
          refetch();
        }}
        selectedFeeStructure={state.selectedFeeStructure}
      />

      {/* Update Route Dialog */}
      {state.routeDialog && (
        <AddRouteDialog
          open={state.routeDialog}
          routeData={state.routeData}
          onClose={() =>
            setState((prev) => ({
              ...prev,
              routeDialog: false,
              routeData: {},
            }))
          }
          refetch={refetch}
        />
      )}

      {/* Create/Update Stop */}
      {state.stopDialog && (
        <AddStopName
          open={state.stopDialog}
          stopData={state.stopData}
          routeData={routeData}
          onClose={() => {
            setState((prev) => ({
              ...prev,
              stopDialog: false,
              stopData: {},
            }));
          }}
          refetch={() => {
            routeRefetch();
            refetch();
          }}
        />
      )}

      {/* Create/Update Range */}
      {state.rangeDialog && (
        <AddDistanceRange
          open={state.rangeDialog}
          rangeData={state.rangeData}
          routeData={routeData}
          vehicleList={vehicleList}
          onClose={() => {
            setState((prev) => ({
              ...prev,
              rangeDialog: false,
              rangeData: {},
            }));
          }}
        />
      )}
    </>
  );
};

export default ViewRouteDetail;
